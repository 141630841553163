const pieces = [
  {
    file: '2019-09_02-16-12-35.gif',
    description: '',
    type: 'gif',
  },
  {
    file: '/images/a001.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/a004.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/a006.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/a007.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/afternoon_scene_.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/afternoon_sofa_making09.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/afternoon_table_.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/img025-02.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/img138-02.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/img149-02.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/img335.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/img929-3.jpg',
    description: '',
    type: 'jpg',
  },
  {
    file: '/images/img985-2.jpg',
    description: '',
    type: 'jpg',
  },
];

export default pieces;
