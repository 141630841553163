import React from 'react';
import './App.scss';
import Piece from './components/Piece';
import pieces from './piecesList';

function App() {
  return (
    <div className='App'>
      <h2>minjeongkang</h2>
      <div className='piece-container'>
        {pieces.map(piece => (
          <Piece piece={piece} key={pieces.indexOf(piece)} />
        ))}
      </div>
    </div>
  );
}

export default App;
