import React, { lazy, Suspense } from 'react';
import gif1 from '../gifs/2019-09-02-16-12-35.gif';
import './piece.scss';

const LazyImg = lazy(() => import('./LazyImg'));

function Piece({ piece }) {
  return (
    <div className='piece'>
      {piece.type === 'gif' ? (
        <img src={gif1} alt={piece.file} className='image' />
      ) : (
        <Suspense fallback={<div></div>}>
          <LazyImg src={piece.file} />
        </Suspense>
      )}
      <span className='text'>{piece.description}</span>
    </div>
  );
}

export default Piece;
